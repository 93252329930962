export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_SECTIONS':
      return action.payload;
    case 'UPDATE_SECTION':
      console.log(action.payload.section_id)
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          console.log("found it")
          return item
        }
        return { ...item, ...action.payload }
      })
    case 'REMOVE_SECTION':
      return state.filter((item) => item.id !== action.payload)
    case 'ADD_SECTION':
      return [...state, action.payload];
    default:
      return state;
  }
};
