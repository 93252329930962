export default (state = [{}], action) => {
  switch (action.type) {
    case 'FETCH_TRUSTS':
      return action.payload;
    case 'UPDATE_TRUST':
      console.log(action.payload.trust_id)
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item
        }
        return { ...item, ...action.payload }
      })
    case 'REMOVE_TRUST':

      return state.filter((item) => item.id !== action.payload)
    case 'ADD_TRUST':
      return [...state, action.payload];
    default:
      return state;

  }
};
