/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTile = /* GraphQL */ `
  mutation CreateTile(
    $input: CreateTileInput!
    $condition: ModelTileConditionInput
  ) {
    createTile(input: $input, condition: $condition) {
      id
      user_id
      user_name
      tile_id
      title
      destination
      icon
      destination_name
      index_number
      colour
      restrict_to_admin
      timestamp
      junction_page_selected
      JunctionPage
      alternative_link
      createdAt
      updatedAt
    }
  }
`;
export const updateTile = /* GraphQL */ `
  mutation UpdateTile(
    $input: UpdateTileInput!
    $condition: ModelTileConditionInput
  ) {
    updateTile(input: $input, condition: $condition) {
      id
      user_id
      user_name
      tile_id
      title
      destination
      icon
      destination_name
      index_number
      colour
      restrict_to_admin
      timestamp
      junction_page_selected
      JunctionPage
      alternative_link
      createdAt
      updatedAt
    }
  }
`;
export const deleteTile = /* GraphQL */ `
  mutation DeleteTile(
    $input: DeleteTileInput!
    $condition: ModelTileConditionInput
  ) {
    deleteTile(input: $input, condition: $condition) {
      id
      user_id
      user_name
      tile_id
      title
      destination
      icon
      destination_name
      index_number
      colour
      restrict_to_admin
      timestamp
      junction_page_selected
      JunctionPage
      alternative_link
      createdAt
      updatedAt
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      user_id
      user_name
      title
      index_number
      page_id
      timestamp
      restrict_to_admin
      junction_page
      junction_data
      createdAt
      updatedAt
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      user_id
      user_name
      title
      index_number
      page_id
      timestamp
      restrict_to_admin
      junction_page
      junction_data
      createdAt
      updatedAt
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      user_id
      user_name
      title
      index_number
      page_id
      timestamp
      restrict_to_admin
      junction_page
      junction_data
      createdAt
      updatedAt
    }
  }
`;
export const createTrust = /* GraphQL */ `
  mutation CreateTrust(
    $input: CreateTrustInput!
    $condition: ModelTrustConditionInput
  ) {
    createTrust(input: $input, condition: $condition) {
      id
      user_id
      timestamp
      active
      image_url
      name
      full_name
      surveys
      trust_id
      trust_image
      user_name
      version
      supported_app_version
      default_pathway
      enable_pathway_selection
      pathways {
        items {
          id
          user_id
          user_name
          title
          cms_title
          timeline_data
          image_url
          hero_section
          wellness_forms_array
          url
          redirect
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTrust = /* GraphQL */ `
  mutation UpdateTrust(
    $input: UpdateTrustInput!
    $condition: ModelTrustConditionInput
  ) {
    updateTrust(input: $input, condition: $condition) {
      id
      user_id
      timestamp
      active
      image_url
      name
      full_name
      surveys
      trust_id
      trust_image
      user_name
      version
      supported_app_version
      default_pathway
      enable_pathway_selection
      pathways {
        items {
          id
          user_id
          user_name
          title
          cms_title
          timeline_data
          image_url
          hero_section
          wellness_forms_array
          url
          redirect
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTrust = /* GraphQL */ `
  mutation DeleteTrust(
    $input: DeleteTrustInput!
    $condition: ModelTrustConditionInput
  ) {
    deleteTrust(input: $input, condition: $condition) {
      id
      user_id
      timestamp
      active
      image_url
      name
      full_name
      surveys
      trust_id
      trust_image
      user_name
      version
      supported_app_version
      default_pathway
      enable_pathway_selection
      pathways {
        items {
          id
          user_id
          user_name
          title
          cms_title
          timeline_data
          image_url
          hero_section
          wellness_forms_array
          url
          redirect
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPathway = /* GraphQL */ `
  mutation CreatePathway(
    $input: CreatePathwayInput!
    $condition: ModelPathwayConditionInput
  ) {
    createPathway(input: $input, condition: $condition) {
      id
      user_id
      user_name
      title
      cms_title
      timeline_data
      image_url
      hero_section
      wellness_forms_array
      url
      redirect
      version
      createdAt
      updatedAt
    }
  }
`;
export const updatePathway = /* GraphQL */ `
  mutation UpdatePathway(
    $input: UpdatePathwayInput!
    $condition: ModelPathwayConditionInput
  ) {
    updatePathway(input: $input, condition: $condition) {
      id
      user_id
      user_name
      title
      cms_title
      timeline_data
      image_url
      hero_section
      wellness_forms_array
      url
      redirect
      version
      createdAt
      updatedAt
    }
  }
`;
export const deletePathway = /* GraphQL */ `
  mutation DeletePathway(
    $input: DeletePathwayInput!
    $condition: ModelPathwayConditionInput
  ) {
    deletePathway(input: $input, condition: $condition) {
      id
      user_id
      user_name
      title
      cms_title
      timeline_data
      image_url
      hero_section
      wellness_forms_array
      url
      redirect
      version
      createdAt
      updatedAt
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection(
    $input: CreateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    createSection(input: $input, condition: $condition) {
      id
      user_id
      timestamp
      colour
      destination
      ext_link
      icon
      image_hash
      image_url
      image_type
      image_style
      index_number
      original_image_key
      section_id
      text
      title
      type
      user_name
      data
      page_link_type
      alternative_link
      button_text
      checkbox_text
      createdAt
      updatedAt
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection(
    $input: UpdateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    updateSection(input: $input, condition: $condition) {
      id
      user_id
      timestamp
      colour
      destination
      ext_link
      icon
      image_hash
      image_url
      image_type
      image_style
      index_number
      original_image_key
      section_id
      text
      title
      type
      user_name
      data
      page_link_type
      alternative_link
      button_text
      checkbox_text
      createdAt
      updatedAt
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection(
    $input: DeleteSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    deleteSection(input: $input, condition: $condition) {
      id
      user_id
      timestamp
      colour
      destination
      ext_link
      icon
      image_hash
      image_url
      image_type
      image_style
      index_number
      original_image_key
      section_id
      text
      title
      type
      user_name
      data
      page_link_type
      alternative_link
      button_text
      checkbox_text
      createdAt
      updatedAt
    }
  }
`;
export const createJunctionPage = /* GraphQL */ `
  mutation CreateJunctionPage(
    $input: CreateJunctionPageInput!
    $condition: ModelJunctionPageConditionInput
  ) {
    createJunctionPage(input: $input, condition: $condition) {
      id
      user_id
      junction_data
      title
      createdAt
      updatedAt
    }
  }
`;
export const updateJunctionPage = /* GraphQL */ `
  mutation UpdateJunctionPage(
    $input: UpdateJunctionPageInput!
    $condition: ModelJunctionPageConditionInput
  ) {
    updateJunctionPage(input: $input, condition: $condition) {
      id
      user_id
      junction_data
      title
      createdAt
      updatedAt
    }
  }
`;
export const deleteJunctionPage = /* GraphQL */ `
  mutation DeleteJunctionPage(
    $input: DeleteJunctionPageInput!
    $condition: ModelJunctionPageConditionInput
  ) {
    deleteJunctionPage(input: $input, condition: $condition) {
      id
      user_id
      junction_data
      title
      createdAt
      updatedAt
    }
  }
`;
export const createWellnessForm = /* GraphQL */ `
  mutation CreateWellnessForm(
    $input: CreateWellnessFormInput!
    $condition: ModelWellnessFormConditionInput
  ) {
    createWellnessForm(input: $input, condition: $condition) {
      id
      user_name
      user_id
      title
      colour
      form_array
      icon
      index_number
      createdAt
      updatedAt
    }
  }
`;
export const updateWellnessForm = /* GraphQL */ `
  mutation UpdateWellnessForm(
    $input: UpdateWellnessFormInput!
    $condition: ModelWellnessFormConditionInput
  ) {
    updateWellnessForm(input: $input, condition: $condition) {
      id
      user_name
      user_id
      title
      colour
      form_array
      icon
      index_number
      createdAt
      updatedAt
    }
  }
`;
export const deleteWellnessForm = /* GraphQL */ `
  mutation DeleteWellnessForm(
    $input: DeleteWellnessFormInput!
    $condition: ModelWellnessFormConditionInput
  ) {
    deleteWellnessForm(input: $input, condition: $condition) {
      id
      user_name
      user_id
      title
      colour
      form_array
      icon
      index_number
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      form_id
      user_name
      user_id
      title
      sub_title
      stub
      question
      answer_array
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      form_id
      user_name
      user_id
      title
      sub_title
      stub
      question
      answer_array
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      form_id
      user_name
      user_id
      title
      sub_title
      stub
      question
      answer_array
      createdAt
      updatedAt
    }
  }
`;
export const createTrigger = /* GraphQL */ `
  mutation CreateTrigger(
    $input: CreateTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    createTrigger(input: $input, condition: $condition) {
      id
      user_name
      user_id
      title
      text
      action
      destination
      call_to_action
      colour
      createdAt
      updatedAt
    }
  }
`;
export const updateTrigger = /* GraphQL */ `
  mutation UpdateTrigger(
    $input: UpdateTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    updateTrigger(input: $input, condition: $condition) {
      id
      user_name
      user_id
      title
      text
      action
      destination
      call_to_action
      colour
      createdAt
      updatedAt
    }
  }
`;
export const deleteTrigger = /* GraphQL */ `
  mutation DeleteTrigger(
    $input: DeleteTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    deleteTrigger(input: $input, condition: $condition) {
      id
      user_name
      user_id
      title
      text
      action
      destination
      call_to_action
      colour
      createdAt
      updatedAt
    }
  }
`;
export const createAnalytics = /* GraphQL */ `
  mutation CreateAnalytics(
    $input: CreateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    createAnalytics(input: $input, condition: $condition) {
      id
      analytics_id
      meta_1
      meta_2
      meta_3
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateAnalytics = /* GraphQL */ `
  mutation UpdateAnalytics(
    $input: UpdateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    updateAnalytics(input: $input, condition: $condition) {
      id
      analytics_id
      meta_1
      meta_2
      meta_3
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnalytics = /* GraphQL */ `
  mutation DeleteAnalytics(
    $input: DeleteAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    deleteAnalytics(input: $input, condition: $condition) {
      id
      analytics_id
      meta_1
      meta_2
      meta_3
      data
      createdAt
      updatedAt
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      user_id
      title
      original_image
      image_url
      low_res_image_url
      hash
      cached_image
      data
      tags
      version_id
      createdAt
      updatedAt
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      user_id
      title
      original_image
      image_url
      low_res_image_url
      hash
      cached_image
      data
      tags
      version_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      user_id
      title
      original_image
      image_url
      low_res_image_url
      hash
      cached_image
      data
      tags
      version_id
      createdAt
      updatedAt
    }
  }
`;
export const createSystemInfo = /* GraphQL */ `
  mutation CreateSystemInfo(
    $input: CreateSystemInfoInput!
    $condition: ModelSystemInfoConditionInput
  ) {
    createSystemInfo(input: $input, condition: $condition) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const updateSystemInfo = /* GraphQL */ `
  mutation UpdateSystemInfo(
    $input: UpdateSystemInfoInput!
    $condition: ModelSystemInfoConditionInput
  ) {
    updateSystemInfo(input: $input, condition: $condition) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const deleteSystemInfo = /* GraphQL */ `
  mutation DeleteSystemInfo(
    $input: DeleteSystemInfoInput!
    $condition: ModelSystemInfoConditionInput
  ) {
    deleteSystemInfo(input: $input, condition: $condition) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const createOnboardingInfo = /* GraphQL */ `
  mutation CreateOnboardingInfo(
    $input: CreateOnboardingInfoInput!
    $condition: ModelOnboardingInfoConditionInput
  ) {
    createOnboardingInfo(input: $input, condition: $condition) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      message_title
      message_subtext
      createdAt
      updatedAt
    }
  }
`;
export const updateOnboardingInfo = /* GraphQL */ `
  mutation UpdateOnboardingInfo(
    $input: UpdateOnboardingInfoInput!
    $condition: ModelOnboardingInfoConditionInput
  ) {
    updateOnboardingInfo(input: $input, condition: $condition) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      message_title
      message_subtext
      createdAt
      updatedAt
    }
  }
`;
export const deleteOnboardingInfo = /* GraphQL */ `
  mutation DeleteOnboardingInfo(
    $input: DeleteOnboardingInfoInput!
    $condition: ModelOnboardingInfoConditionInput
  ) {
    deleteOnboardingInfo(input: $input, condition: $condition) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      message_title
      message_subtext
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      user_hash
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user_hash
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      user_hash
      createdAt
      updatedAt
    }
  }
`;
export const createCheck = /* GraphQL */ `
  mutation CreateCheck(
    $input: CreateCheckInput!
    $condition: ModelCheckConditionInput
  ) {
    createCheck(input: $input, condition: $condition) {
      id
      user_hash
      check_id
      checked
      createdAt
      updatedAt
    }
  }
`;
export const updateCheck = /* GraphQL */ `
  mutation UpdateCheck(
    $input: UpdateCheckInput!
    $condition: ModelCheckConditionInput
  ) {
    updateCheck(input: $input, condition: $condition) {
      id
      user_hash
      check_id
      checked
      createdAt
      updatedAt
    }
  }
`;
export const deleteCheck = /* GraphQL */ `
  mutation DeleteCheck(
    $input: DeleteCheckInput!
    $condition: ModelCheckConditionInput
  ) {
    deleteCheck(input: $input, condition: $condition) {
      id
      user_hash
      check_id
      checked
      createdAt
      updatedAt
    }
  }
`;
