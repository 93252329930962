export default (state = [], action) => {
  switch (action.type) {
    case "FETCH_SYSTEM_INFO_BY_DOMAIN":
      console.log("in reducer....FETCH_SYSTEM_INFO_BY_DOMAIN");
      console.log(action.payload);
      return action.payload;
    case "UPDATE_SYSTEM_INFO":
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }
        return { ...item, ...action.payload };
      });
    case "REMOVE_SYSTEM_INFO":
      console.log("ARE WE HERE");
      return state.filter((item) => item.id !== action.payload);
    case "ADD_SYSTEM_INFO":
      return [...state, action.payload];
    default:
      return state;
  }
};
