const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'UPDATE_USER_INFO':
      return {...state, ...action.payload}
    default:
      return state;
  }
};
