export default (state = [], action) => {
  switch (action.type) {
    case "FETCH_TILES":
      console.log("in TILES reducer....");
      console.log(action.payload);
      return action.payload;
    case "UPDATE_TILE":
      console.log(action.payload.tile_id);
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item;
        }
        return { ...item, ...action.payload };
      });
    case "REMOVE_TILE":
      return state.filter((item) => item.id !== action.payload);
    case "ADD_TILE":
      return [...state, action.payload];
    default:
      return state;
  }
};
