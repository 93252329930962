export default (state = [], action) => {
  switch (action.type) {
    case "FETCH_TEMPLATES":
      return action.payload;
    case "UPDATE_TEMPLATE":
      console.log(action.payload.page_id);
      return state.map((item) => {
        if (item.page_id !== action.payload.page_id) {
          return item;
        }
        return { ...item, ...action.payload };
      });
    case "REMOVE_TEMPLATE":
      state.filter((item) => console.log(item.id + action.payload));
      return state.filter((item) => item.id !== action.payload);
    case "ADD_TEMPLATE":
      return [...state, action.payload];
    default:
      return state;
  }
};
