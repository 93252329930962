export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_JUNCTION_PAGES':
      return action.payload;
    case 'UPDATE_JUNCTION_PAGE':
      console.log(action.payload.id)
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item
        }
        return { ...item, ...action.payload }
      })
    case 'REMOVE_JUNCTION_PAGE':
      state.filter((item) => console.log(item.id + action.payload))
      return state.filter((item) => item.id !== action.payload)
    case 'ADD_JUNCTION_PAGE':
      console.log('im in the reducer adding JP')
      return [...state, action.payload];
    default:
      return state;
  }
};
