export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_PATHWAYS':
      console.log('in reducer....FETCHING PATHWAYS')
      console.log(action.payload)
      return action.payload;
    case 'UPDATE_PATHWAY':
      console.log(action.payload.id)
      
      return state.map((item) => {
        console.log(item)
        if (item.id !== action.payload.id) {
          return item
        }
        return { ...item, ...action.payload }
      })
    case 'REMOVE_PATHWAY':
      return state.filter((item) => item.id !== action.payload)
    case 'ADD_PATHWAY':
      return [...state, action.payload];
    default:
      return state;

  }
};
