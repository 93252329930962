// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-data-index-js": () => import("./../../../src/pages/add_data/index.js" /* webpackChunkName: "component---src-pages-add-data-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-user-edit-js": () => import("./../../../src/pages/admin/user/edit.js" /* webpackChunkName: "component---src-pages-admin-user-edit-js" */),
  "component---src-pages-analytics-index-js": () => import("./../../../src/pages/analytics/index.js" /* webpackChunkName: "component---src-pages-analytics-index-js" */),
  "component---src-pages-api-index-js": () => import("./../../../src/pages/api/index.js" /* webpackChunkName: "component---src-pages-api-index-js" */),
  "component---src-pages-archive-index-js": () => import("./../../../src/pages/archive/index.js" /* webpackChunkName: "component---src-pages-archive-index-js" */),
  "component---src-pages-auth-index-js": () => import("./../../../src/pages/auth/index.js" /* webpackChunkName: "component---src-pages-auth-index-js" */),
  "component---src-pages-content-index-js": () => import("./../../../src/pages/content/index.js" /* webpackChunkName: "component---src-pages-content-index-js" */),
  "component---src-pages-images-index-js": () => import("./../../../src/pages/images/index.js" /* webpackChunkName: "component---src-pages-images-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monitoring-index-js": () => import("./../../../src/pages/monitoring/index.js" /* webpackChunkName: "component---src-pages-monitoring-index-js" */),
  "component---src-pages-pages-index-js": () => import("./../../../src/pages/pages/index.js" /* webpackChunkName: "component---src-pages-pages-index-js" */),
  "component---src-pages-pathways-index-js": () => import("./../../../src/pages/pathways/index.js" /* webpackChunkName: "component---src-pages-pathways-index-js" */),
  "component---src-pages-preview-index-js": () => import("./../../../src/pages/preview/index.js" /* webpackChunkName: "component---src-pages-preview-index-js" */),
  "component---src-pages-publish-index-js": () => import("./../../../src/pages/publish/index.js" /* webpackChunkName: "component---src-pages-publish-index-js" */),
  "component---src-pages-sections-index-js": () => import("./../../../src/pages/sections/index.js" /* webpackChunkName: "component---src-pages-sections-index-js" */),
  "component---src-pages-settings-index-js": () => import("./../../../src/pages/settings/index.js" /* webpackChunkName: "component---src-pages-settings-index-js" */),
  "component---src-pages-structure-index-js": () => import("./../../../src/pages/structure/index.js" /* webpackChunkName: "component---src-pages-structure-index-js" */),
  "component---src-pages-surveys-index-js": () => import("./../../../src/pages/surveys/index.js" /* webpackChunkName: "component---src-pages-surveys-index-js" */),
  "component---src-pages-templates-index-js": () => import("./../../../src/pages/templates/index.js" /* webpackChunkName: "component---src-pages-templates-index-js" */),
  "component---src-pages-tiles-index-js": () => import("./../../../src/pages/tiles/index.js" /* webpackChunkName: "component---src-pages-tiles-index-js" */),
  "component---src-pages-trusts-index-js": () => import("./../../../src/pages/trusts/index.js" /* webpackChunkName: "component---src-pages-trusts-index-js" */),
  "component---src-pages-users-index-js": () => import("./../../../src/pages/users/index.js" /* webpackChunkName: "component---src-pages-users-index-js" */),
  "component---src-pages-wellness-index-js": () => import("./../../../src/pages/wellness/index.js" /* webpackChunkName: "component---src-pages-wellness-index-js" */)
}

