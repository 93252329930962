/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTile = /* GraphQL */ `
  query GetTile($id: ID!) {
    getTile(id: $id) {
      id
      user_id
      user_name
      tile_id
      title
      destination
      icon
      destination_name
      index_number
      colour
      restrict_to_admin
      timestamp
      junction_page_selected
      JunctionPage
      alternative_link
      createdAt
      updatedAt
    }
  }
`;
export const listTiles = /* GraphQL */ `
  query ListTiles(
    $filter: ModelTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        user_name
        tile_id
        title
        destination
        icon
        destination_name
        index_number
        colour
        restrict_to_admin
        timestamp
        junction_page_selected
        JunctionPage
        alternative_link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tilesByUserId = /* GraphQL */ `
  query TilesByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tilesByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        user_name
        tile_id
        title
        destination
        icon
        destination_name
        index_number
        colour
        restrict_to_admin
        timestamp
        junction_page_selected
        JunctionPage
        alternative_link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      user_id
      user_name
      title
      index_number
      page_id
      timestamp
      restrict_to_admin
      junction_page
      junction_data
      createdAt
      updatedAt
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        user_name
        title
        index_number
        page_id
        timestamp
        restrict_to_admin
        junction_page
        junction_data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pagesByUserId = /* GraphQL */ `
  query PagesByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pagesByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        user_name
        title
        index_number
        page_id
        timestamp
        restrict_to_admin
        junction_page
        junction_data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pagesByPageId = /* GraphQL */ `
  query PagesByPageId(
    $page_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pagesByPageId(
      page_id: $page_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        user_name
        title
        index_number
        page_id
        timestamp
        restrict_to_admin
        junction_page
        junction_data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrust = /* GraphQL */ `
  query GetTrust($id: ID!) {
    getTrust(id: $id) {
      id
      user_id
      timestamp
      active
      image_url
      name
      full_name
      surveys
      trust_id
      trust_image
      user_name
      version
      supported_app_version
      default_pathway
      enable_pathway_selection
      pathways {
        items {
          id
          user_id
          user_name
          title
          cms_title
          timeline_data
          image_url
          hero_section
          wellness_forms_array
          url
          redirect
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTrusts = /* GraphQL */ `
  query ListTrusts(
    $filter: ModelTrustFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrusts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        timestamp
        active
        image_url
        name
        full_name
        surveys
        trust_id
        trust_image
        user_name
        version
        supported_app_version
        default_pathway
        enable_pathway_selection
        pathways {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const trustByUserId = /* GraphQL */ `
  query TrustByUserId(
    $trust_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTrustFilterInput
    $limit: Int
    $nextToken: String
  ) {
    trustByUserId(
      trust_id: $trust_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        timestamp
        active
        image_url
        name
        full_name
        surveys
        trust_id
        trust_image
        user_name
        version
        supported_app_version
        default_pathway
        enable_pathway_selection
        pathways {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPathway = /* GraphQL */ `
  query GetPathway($id: ID!) {
    getPathway(id: $id) {
      id
      user_id
      user_name
      title
      cms_title
      timeline_data
      image_url
      hero_section
      wellness_forms_array
      url
      redirect
      version
      createdAt
      updatedAt
    }
  }
`;
export const listPathways = /* GraphQL */ `
  query ListPathways(
    $filter: ModelPathwayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPathways(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        user_name
        title
        cms_title
        timeline_data
        image_url
        hero_section
        wellness_forms_array
        url
        redirect
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const pathwayByUserId = /* GraphQL */ `
  query PathwayByUserId(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPathwayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PathwayByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        user_name
        title
        cms_title
        timeline_data
        image_url
        hero_section
        wellness_forms_array
        hero_section
        url
        redirect
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      user_id
      timestamp
      colour
      destination
      ext_link
      icon
      image_hash
      image_url
      image_type
      image_style
      index_number
      original_image_key
      section_id
      text
      title
      type
      user_name
      data
      page_link_type
      alternative_link
      button_text
      checkbox_text
      createdAt
      updatedAt
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        timestamp
        colour
        destination
        ext_link
        icon
        image_hash
        image_url
        image_type
        image_style
        index_number
        original_image_key
        section_id
        text
        title
        type
        user_name
        data
        page_link_type
        alternative_link
        button_text
        checkbox_text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sectionsByUserId = /* GraphQL */ `
  query SectionsByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sectionsByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        timestamp
        colour
        destination
        ext_link
        icon
        image_hash
        image_url
        image_type
        image_style
        index_number
        original_image_key
        section_id
        text
        title
        type
        user_name
        data
        page_link_type
        alternative_link
        button_text
        checkbox_text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sectionsSectionId = /* GraphQL */ `
  query SectionsSectionId(
    $section_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sectionsSectionId(
      section_id: $section_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        timestamp
        colour
        destination
        ext_link
        icon
        image_hash
        image_url
        image_type
        image_style
        index_number
        original_image_key
        section_id
        text
        title
        type
        user_name
        data
        page_link_type
        alternative_link
        button_text
        checkbox_text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJunctionPage = /* GraphQL */ `
  query GetJunctionPage($id: ID!) {
    getJunctionPage(id: $id) {
      id
      user_id
      junction_data
      title
      createdAt
      updatedAt
    }
  }
`;
export const listJunctionPages = /* GraphQL */ `
  query ListJunctionPages(
    $filter: ModelJunctionPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJunctionPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        junction_data
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const junctionPagesByUserId = /* GraphQL */ `
  query JunctionPagesByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelJunctionPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    junctionPagesByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        junction_data
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWellnessForm = /* GraphQL */ `
  query GetWellnessForm($id: ID!) {
    getWellnessForm(id: $id) {
      id
      user_name
      user_id
      title
      colour
      form_array
      icon
      index_number
      createdAt
      updatedAt
    }
  }
`;
export const listWellnessForms = /* GraphQL */ `
  query ListWellnessForms(
    $filter: ModelWellnessFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWellnessForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_name
        user_id
        title
        colour
        form_array
        icon
        index_number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const wellnessFormByUserId = /* GraphQL */ `
  query WellnessFormByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWellnessFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WellnessFormByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_name
        user_id
        title
        colour
        form_array
        icon
        index_number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      form_id
      user_name
      user_id
      title
      sub_title
      stub
      question
      answer_array
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        form_id
        user_name
        user_id
        title
        sub_title
        stub
        question
        answer_array
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionByUserId = /* GraphQL */ `
  query QuestionByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        form_id
        user_name
        user_id
        title
        sub_title
        stub
        question
        answer_array
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTrigger = /* GraphQL */ `
  query GetTrigger($id: ID!) {
    getTrigger(id: $id) {
      id
      user_name
      user_id
      title
      text
      action
      destination
      call_to_action
      colour
      createdAt
      updatedAt
    }
  }
`;
export const listTriggers = /* GraphQL */ `
  query ListTriggers(
    $filter: ModelTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_name
        user_id
        title
        text
        action
        destination
        call_to_action
        colour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const triggersByUserId = /* GraphQL */ `
  query TriggersByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TriggersByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_name
        user_id
        title
        text
        action
        destination
        call_to_action
        colour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAnalytics = /* GraphQL */ `
  query GetAnalytics($id: ID!) {
    getAnalytics(id: $id) {
      id
      analytics_id
      meta_1
      meta_2
      meta_3
      data
      createdAt
      updatedAt
    }
  }
`;
export const listAnalytics = /* GraphQL */ `
  query ListAnalytics(
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalytics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        analytics_id
        meta_1
        meta_2
        meta_3
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const analyticsById = /* GraphQL */ `
  query AnalyticsById(
    $analytics_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AnalyticsById(
      analytics_id: $analytics_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        analytics_id
        meta_1
        meta_2
        meta_3
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      user_id
      title
      original_image
      image_url
      low_res_image_url
      hash
      cached_image
      data
      tags
      version_id
      createdAt
      updatedAt
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        title
        original_image
        image_url
        low_res_image_url
        hash
        cached_image
        data
        tags
        version_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const imagesByUserId = /* GraphQL */ `
  query ImagesByUserId(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    imagesByUserId(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        title
        original_image
        image_url
        low_res_image_url
        hash
        cached_image
        data
        tags
        version_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSystemInfo = /* GraphQL */ `
  query GetSystemInfo($id: ID!) {
    getSystemInfo(id: $id) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const listSystemInfos = /* GraphQL */ `
  query ListSystemInfos(
    $filter: ModelSystemInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSystemInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        domain
        type
        landing_option
        trust_id
        target
        data
        data1
        data2
        data3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const systemInfoByDomain = /* GraphQL */ `
  query SystemInfoByDomain(
    $domain: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSystemInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SystemInfoByDomain(
      domain: $domain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        domain
        type
        landing_option
        trust_id
        target
        data
        data1
        data2
        data3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOnboardingInfo = /* GraphQL */ `
  query GetOnboardingInfo($id: ID!) {
    getOnboardingInfo(id: $id) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      message_title
      message_subtext
      createdAt
      updatedAt
    }
  }
`;
export const listOnboardingInfos = /* GraphQL */ `
  query ListOnboardingInfos(
    $filter: ModelOnboardingInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardingInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        domain
        type
        landing_option
        trust_id
        target
        data
        data1
        data2
        data3
        message_title
        message_subtext
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const onboardingInfoByDomain = /* GraphQL */ `
  query OnboardingInfoByDomain(
    $domain: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardingInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OnboardingInfoByDomain(
      domain: $domain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        domain
        type
        landing_option
        trust_id
        target
        data
        data1
        data2
        data3
        message_title
        message_subtext
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_hash
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_hash
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByHash = /* GraphQL */ `
  query UserByHash(
    $user_hash: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByHash(
      user_hash: $user_hash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_hash
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCheck = /* GraphQL */ `
  query GetCheck($id: ID!) {
    getCheck(id: $id) {
      id
      user_hash
      check_id
      checked
      createdAt
      updatedAt
    }
  }
`;
export const listChecks = /* GraphQL */ `
  query ListChecks(
    $filter: ModelCheckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_hash
        check_id
        checked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const checkByUserHash = /* GraphQL */ `
  query CheckByUserHash(
    $user_hash: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CheckByUserHash(
      user_hash: $user_hash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_hash
        check_id
        checked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const checkByCheckID = /* GraphQL */ `
  query CheckByCheckID(
    $check_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCheckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CheckByCheckID(
      check_id: $check_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_hash
        check_id
        checked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dataImporter = /* GraphQL */ `
  query DataImporter($meta: String) {
    dataImporter(meta: $meta)
  }
`;
export const onboardingFunction = /* GraphQL */ `
  query OnboardingFunction($meta: String) {
    onboardingFunction(meta: $meta)
  }
`;
export const processImage = /* GraphQL */ `
  query ProcessImage($image: String) {
    processImage(image: $image)
  }
`;
export const publish = /* GraphQL */ `
  query Publish($meta: String) {
    publish(meta: $meta)
  }
`;
