import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import tilesReducer from "./tilesReducer";
import pathwaysReducer from "./pathwaysReducer";
import pagesReducer from "./pagesReducer";
import templatesReducer from "./templatesReducer";
import sectionsReducer from "./sectionsReducer";
import trustsReducer from "./trustsReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import junctionPageReducer from "./junctionPageReducer";
import wellnessFormReducer from "./wellnessFormReducer";
import wellnessQuestionReducer from "./wellnessQuestionReducer";
import wellnessTriggers from "./wellnessTriggerReducer";
import analyticsReducer from "./analyticsReducer";
import systemInfoReducer from "./systemInfoReducer";
import imagesReducer from "./imagesReducer";

export default combineReducers({
  tiles: tilesReducer,
  pages: pagesReducer,
  templates: templatesReducer,
  sections: sectionsReducer,
  pathways: pathwaysReducer,
  trusts: trustsReducer,
  auth: authReducer,
  form: formReducer,
  user: userReducer,
  junctionPages: junctionPageReducer,
  wellnessForm: wellnessFormReducer,
  wellnessQuestion: wellnessQuestionReducer,
  wellnessTriggers: wellnessTriggers,
  analytics: analyticsReducer,
  systemInfo: systemInfoReducer,
  images: imagesReducer,
});
