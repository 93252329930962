const INITIAL_STATE = {
  hasCheckedAuth: false,
  isAuthenticated: false,
  isAuthenticating: false,
  isLoading: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOG_IN':
      console.log("in reducer")
      return {...state, ...action.payload}
    default:
      return state;
  }
};