import React, { useState, useEffect } from "react";

import "semantic-ui-css/semantic.min.css";
import wrapWithProvider from "./wrap-with-provider";
import { Amplify } from "aws-amplify";
import config from "./src/aws-exports";
import config_master from "./src/aws-exports-master";
import GitInfo from "react-git-info/macro";

var branch_name = GitInfo().branch;
Amplify.configure(config);
// if (
//   branch_name === "master" ||
//   branch_name === "prod-pre-deploy-v2" ||
//   branch_name === "prod-pre-deploy"
// ) {
//   Amplify.configure(config_master);
// } else {
//   Amplify.configure(config);
// }
// Amplify.configure({
//   Auth: {
//     identityPoolId: config["aws_cognito_identity_pool_id"], //REQUIRED - Amazon Cognito Identity Pool ID
//     region: config["aws_project_region"], // REQUIRED - Amazon Cognito Region
//     userPoolId: config["aws_user_pools_id"], //OPTIONAL - Amazon Cognito User Pool ID
//     userPoolWebClientId: config["aws_user_pools_web_client_id"], //OPTIONAL - Amazon Cognito Web Client ID
//   },
//   Storage: {
//     AWSS3: {
//       bucket: config["aws_user_files_s3_bucket"], //REQUIRED -  Amazon S3 bucket
//       region: config["aws_user_files_s3_bucket_region"], //OPTIONAL -  Amazon service region
//     },
//   },
// });
export const wrapRootElement = wrapWithProvider;
