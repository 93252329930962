export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_WELLNESS_QUESTIONS':
      console.log('in reducer....FETCHING WELLNESS_QUESTION')
      console.log(action.payload)
      return action.payload;
    case 'UPDATE_WELLNESS_QUESTION':
      console.log(action.payload.id)
      return state.map((item) => {
        if (item.id !== action.payload.id) {
          return item
        }
        return { ...item, ...action.payload }
      })
    case 'REMOVE_WELLNESS_QUESTION':
      console.log('ARE WE HERE')
      return state.filter((item) => item.id !== action.payload)
    case 'ADD_WELLNESS_QUESTION':
      return [...state, action.payload];
    default:
      return state;

  }
};
