import React from "react";
import { Provider } from "react-redux";
import createStore from "./src/state/createStore";
import {
  authStatus,
  updateUserInfo,
  fetchTrusts,
  fetchTiles,
  fetchPages,
  fetchSections,
} from "./src/actions";
import { Auth, Cache } from "aws-amplify";

async function checkUser(store) {
  const state = store.getState();
  console.log("helllooooo");

  if (state.auth.hasCheckedAuth === false) {
    try {
      console.log("say something");
      const userInfo = await Auth.currentSession();
      console.log(userInfo);

      if (userInfo) {
        console.log("we have a user session");
        let user = await Auth.currentAuthenticatedUser();
        console.log(user);
        console.log(user.attributes);
        console.log(user.signInUserSession.idToken.payload["cognito:groups"]);
        store.dispatch(updateUserInfo(user.attributes));
        store.dispatch(fetchTrusts());
        store.dispatch(
          authStatus({ isAuthenticated: true, hasCheckedAuth: true })
        );
      }
    } catch (error) {}
  }
}

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  Cache.clear();
  const store = createStore();
  checkUser(store);

  return <Provider store={store}>{element}</Provider>;
};
