export default (state = [], action) => {
  switch (action.type) {
    case 'FETCH_ANALYTICS':
      console.log('in reducer....FETCHING ANALYTICS')
      console.log(action.payload)
      return action.payload;
    // case 'UPDATE_WELLNESS_FORM':
    //   console.log(action.payload.id)
    //   return state.map((item) => {
    //     if (item.id !== action.payload.id) {
    //       return item
    //     }
    //     return { ...item, ...action.payload }
    //   })
    case 'REMOVE_WELLNESS_ANALYTICS':
      console.log('ARE WE HERE')
      return state.filter((item) => item.id !== action.payload)
    // case 'ADD_WELLNESS_FORM':
    //   return [...state, action.payload];
    default:
      return state;

  }
};
